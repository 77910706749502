.classrooms {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 4rem;
  margin: 1.5rem 0;

  .classroom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
    padding: 1.5rem;

    .image-container {
      position: relative;

      .icons {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        display: flex;
        gap: 1rem;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          background-color: white;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
          }
        }
        .icon:hover {
          background-color: #f5f5f5;
        }
      }

      .image {
        width: 100%;
        height: 210px;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    .header {
      h2 {
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0;
      }
      span {
        color: #979797;
      }
    }

    .location {
      display: flex;
      align-items: center;

      img {
        width: 25px;
        height: 26px;
        margin-right: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .classrooms {
    grid-template-columns: 2fr;
    .classroom {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .classrooms {
    grid-template-columns: 1fr;
    .classroom {
      width: 100%;
    }
  }
}
