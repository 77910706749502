.input_container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.input {
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--black);
  outline: 1px solid var(--Primary-Gray-200);
  border-radius: 12px;
  width: 100%;
  transition: outline-color 0.2s ease;
  &:hover {
    outline-color: var(--Primary-ParadisePink-500);
  }
  &:focus {
    outline: 2px solid var(--Primary-ParadisePink-500);
  }
}

.input_error {
  border: 1px solid var(--Tertiary-Severe-500);
  background-color: var(--Tertiary-Severe-50);
}

.error {
  color: #bb003b;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

.password_field {
  display: flex;
  align-items: center;
  justify-content: right;
}
.password_icon {
  width: 20px;
  position: absolute;
  margin-right: 15px;
  cursor: pointer;
}

.input::placeholder {
  color: var(--Primary-Gray-300);
}

.input_search::placeholder {
  color: #000000;
}
