@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AmpleSoftPro";
  src: url("../public/AmpleSoftPro-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: AmpleSoftPro; */
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--Primary-ParadisePink-850);
}

::selection {
  color: #fff;
  background: #ca2d5d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

:root {
  /* Primary Colors */

  --Primary-ParadisePink-50: #fffafb;
  --Primary-ParadisePink-100: #ffecf2;
  --Primary-ParadisePink-200: #ffc9d9;
  --Primary-ParadisePink-300: #ff99b8;
  --Primary-ParadisePink-400: #fb6694;
  --Primary-ParadisePink-500: #e83c70;
  --Primary-ParadisePink-600: #ca325c;
  --Primary-ParadisePink-700: #811d39;
  --Primary-ParadisePink-750: #5c1025;
  --Primary-ParadisePink-800: #370513;
  --Primary-ParadisePink-850: #1b0209;
  --Primary-ParadisePink-900: #0a0003;

  --Primary-Purple-50: #fdfcff;
  --Primary-Purple-100: #f3edff;
  --Primary-Purple-200: #e2d3ff;
  --Primary-Purple-300: #bf9aff;
  --Primary-Purple-400: #a77bf4;
  --Primary-Purple-500: #8755e0;
  --Primary-Purple-600: #7947d1;
  --Primary-Purple-700: #5f2ba5;
  --Primary-Purple-750: #3d1671;
  --Primary-Purple-800: #1d0839;
  --Primary-Purple-850: #0f0220;
  --Primary-Purple-900: #030007;

  --Primary-NavyBlue-50: #fcfcff;
  --Primary-NavyBlue-100: #f0eeff;
  --Primary-NavyBlue-200: #c3bbff;
  --Primary-NavyBlue-300: #816ef9;
  --Primary-NavyBlue-400: #3e3393;
  --Primary-NavyBlue-500: #1a1449;
  --Primary-NavyBlue-600: #120c3f;
  --Primary-NavyBlue-700: #0e0935;
  --Primary-NavyBlue-750: #09042d;
  --Primary-NavyBlue-800: #060322;
  --Primary-NavyBlue-850: #040117;
  --Primary-NavyBlue-900: #02000c;

  --Primary-Gray-50: #ffffff;
  --Primary-Gray-100: #f5f5f5;
  --Primary-Gray-200: #dfdfdf;
  --Primary-Gray-300: #bcbcbc;
  --Primary-Gray-400: #6d6d6d;
  --Primary-Gray-500: #3f3f3e;
  --Primary-Gray-600: #373737;
  --Primary-Gray-700: #2a2a2a;
  --Primary-Gray-750: #1f1f1f;
  --Primary-Gray-800: #1e1e1e;
  --Primary-Gray-850: #0d0d0d;
  --Primary-Gray-900: #000000;

  /* Secondary Color(s) */

  --Secondary-LemonYellow-50: #fffcf4;
  --Secondary-LemonYellow-100: #fff4d4;
  --Secondary-LemonYellow-200: #ffe9a6;
  --Secondary-LemonYellow-300: #ffd967;
  --Secondary-LemonYellow-400: #ffd046;
  --Secondary-LemonYellow-500: #fbc219;
  --Secondary-LemonYellow-600: #e0a906;
  --Secondary-LemonYellow-700: #b98e0d;
  --Secondary-LemonYellow-750: #71570d;
  --Secondary-LemonYellow-800: #3d2f06;
  --Secondary-LemonYellow-850: #282002;
  --Secondary-LemonYellow-900: #130f01;

  /* Tertiary Colors */

  --Tertiary-Success-50: #fbfffb;
  --Tertiary-Success-100: #f2fff3;
  --Tertiary-Success-200: #d1ffd3;
  --Tertiary-Success-300: #9effa2;
  --Tertiary-Success-400: #6ef673;
  --Tertiary-Success-500: #45ee4c;
  --Tertiary-Success-600: #2cbf32;
  --Tertiary-Success-700: #138628;
  --Tertiary-Success-750: #0c5619;
  --Tertiary-Success-800: #053a0f;
  --Tertiary-Success-850: #001c01;
  --Tertiary-Success-900: #000800;

  --Tertiary-Moderate-50: #fffefb;
  --Tertiary-Moderate-100: #fffaea;
  --Tertiary-Moderate-200: #fff0bc;
  --Tertiary-Moderate-300: #ffe68e;
  --Tertiary-Moderate-400: #ffdc60;
  --Tertiary-Moderate-500: #fcd13b;
  --Tertiary-Moderate-600: #e7bf35;
  --Tertiary-Moderate-700: #c5a227;
  --Tertiary-Moderate-750: #846c16;
  --Tertiary-Moderate-800: #493b09;
  --Tertiary-Moderate-850: #251e03;
  --Tertiary-Moderate-900: #0a0800;

  --Tertiary-Severe-50: #fffcfc;
  --Tertiary-Severe-100: #fff4f4;
  --Tertiary-Severe-200: #ffd4d4;
  --Tertiary-Severe-300: #ffb5b5;
  --Tertiary-Severe-400: #ff6b6b;
  --Tertiary-Severe-500: #ff3e3e;
  --Tertiary-Severe-600: #d22121;
  --Tertiary-Severe-700: #9a1313;
  --Tertiary-Severe-750: #690a0a;
  --Tertiary-Severe-800: #440505;
  --Tertiary-Severe-850: #1b0000;
  --Tertiary-Severe-900: #0b0000;
}

/* Title Styles */
.title-l {
  font-weight: 800;
  font-size: 64px;
  line-height: 70px;
  text-align: center;
}

.title-m {
  font-weight: 800;
  font-size: 52px;
  line-height: 64px;
  text-align: center;
}

.title-s {
  font-weight: 700;
  font-size: 44px;
  line-height: 54px;
  text-align: center;
}

/* Heading Styles */
.heading-large {
  font-weight: 800;
  font-size: 52px;
  line-height: 64px;
}

.heading-medium {
  font-weight: 700;
  font-size: 44px;
  line-height: 54px;
}

.heading {
  font-weight: 700;
  font-size: 38px;
  line-height: 44px;
  text-align: center;
}

.subheading {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}

.heading-4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}
/* Body Styles */
.body-large {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}

.body-large-bold {
  font-weight: 800;
  font-size: 22px;
  line-height: 32px;
}

.body-medium {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.body-medium-linked {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-decoration: underline;
}

.body-medium-bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
}

.body-small {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.body-small-linked {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
}

.body-small-bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

/* Caption Text */
.caption-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

.caption-text-with-link {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-decoration: underline;
  color: blue; /* Assuming a link */
}

.caption-text-bold {
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
}

/* Button Styles */
.large-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.medium-button {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

/* Custom Scrollbar */
.custom_scrollbar {
  overflow-y: auto;
}
.custom_scrollbar::-webkit-scrollbar {
  width: 4px;
}
.custom_scrollbar::-webkit-scrollbar-track {
  background-color: var(--Primary-Gray-200);
  border-radius: 4px;
}
.custom_scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--Primary-Gray-400);
  border-radius: 4px;
  cursor: pointer;
}

/* timetable custom scrollbar */
.timetable_scrollbar {
  overflow-y: auto;
  user-select: none;
}
.timetable_scrollbar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.timetable_scrollbar::-webkit-scrollbar-track {
  background-color: "#FFFFFF";
  border-radius: 99px;
}
.timetable_scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--Primary-Gray-300);
  border-radius: 99px;
  cursor: pointer;
}


.react-resizable {
  position: absolute !important;
}