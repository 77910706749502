.class-header-price-container {
    display: flex;
    align-items: center; 
    gap: 8px; 
  }

.old-price {
    text-decoration: line-through;
    color: #0F0220; 
    margin-right: 1px;
  }
  
  .new-price {
    color: #9B00E8; 
    font-weight: 600;
  }

  .discount-badge {
    background-color: #E83C70; 
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 24px;
    white-space: nowrap; 
  }

/* Container */
.class-header-container {
    display: flex;
    justify-content: center;
    padding: 24px 0;
}

/* Main Header */
.class-header {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    min-height: 124px;
    padding: 12px 24px;
    border: 1px solid #E2D3FF;
    border-radius: 24px;
    background: #FDFCFF;
    position: relative;
}

/* Image Styling */
.class-header-image {
    width: 170px;
    height: 100px;
    border-radius: 12px;
    object-fit: cover;
    border: 1px solid #ccc;
}

/* Course Details */
.class-header-details {
    flex: 1;
    padding-left: 16px;
    color: #370513;

}

/* Instructor & Price Wrapper */
.class-header-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4px;
}

/* Title */
.class-header-title {
    font-size: 20px;
    font-weight: bold;
    color: #370513;
    margin-bottom: 4px;
}

/* Instructor */
.class-header-instructor {
    font-size: 14px;
    color: #666;
    margin-bottom: 6px;
}

/* Price */
.class-header-price {
    font-size: 18px;
    font-weight: bold;
    color: #7947D1;
    flex-direction: row;
}

/* Wishlist Button */
.class-header-wishlist {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
    /* Tablet */
    .class-header {
        max-width: 800px;
        padding: 12px 16px;
    }
    .class-header-image {
        width: 140px;
        height: 80px;
    }
    .class-header-title {
        font-size: 18px;
    }
    .class-header-instructor {
        font-size: 12px;
    }
    .class-header-price {
        font-size: 16px;
    }
    .class-header-wishlist {
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 600px) {
    /* Mobile */
    .class-header {
        max-width: 370px;
        min-height: 137px;
        flex-direction: row;
        align-items: center;
        text-align: center;
        padding: 16px;
    }

    .class-header-image {
        width: 110px;
        height: 65px;
    }

    .class-header-details {
        padding-left: 8px;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
    }

    .class-header-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4px;
    }

    .class-header-title {
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
    }

    .class-header-instructor {
        font-size: 12px;
        line-height: 24px;
        font-weight: 500;
    }

    .class-header-price {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
    }

    .class-header-wishlist {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: 8px;
        right: 8px;
    }


      
}
